import React, { useState, useEffect } from 'react';

export const ExternalLocator = ({ src, iframeAttributes = {} }) => {
  const [iframeQuery, setIframeQuery] = useState('');
  const [showIframe, setShowIframe] = useState(false);
  useEffect(() => {
    const search = window.location.search;
    const query = search.split('?q=')[1] || ''; // split to retrieve query param
    setIframeQuery(query);
    setShowIframe(true);
  }, []);

  const {
    title = 'agency-locator-iframe',
    allow = 'geolocation',
    height = '600px',
    ...otherAttributes
  } = iframeAttributes;

  return (
    showIframe && (
      <iframe
        {...otherAttributes}
        title={title}
        allow={allow}
        height={height}
        src={iframeQuery ? `${src}?q=${iframeQuery}` : src}
      ></iframe>
    )
  );
};

export default ExternalLocator;
