import React from 'react';
import classNames from 'classnames';
import ExternalLocator from './ExternalLocator';
import InternalLocator from './InternalLocator';
import Title from '../../molecules/title/title';
import Icon from '../../atoms/icon';
import {
  getCommonOrganismData,
  parseJsonAttributes,
} from '../../utils/functions';
import { Wrapper } from '../../utils/components';
import withPreview from '../../utils/with-preview';

import './AgencyLocator.scss';
import AnchorHooking from '../../molecules/anchor-hooking/AnchorHooking';
import slugify from 'slugify';

const locatorVisualizationModes = {
  MAP: 'map',
  NO_MAP: 'nomap',
};

const AgencyLocator = ({ data, considerMenuSpace }) => {
  const {
    htmlTagWrapper,
    backgroundColor,
    anchorLabel,
    title,
    titleHeadingTag,
    titleIconId,
    marginTop,
    titleIconThickness,
  } = getCommonOrganismData(data);

  const externalAgencyLocatorUrl = data?.externalAgencyLocatorUrl?.value;

  const iframeAttributes = parseJsonAttributes(data?.attributesIframe?.value);

  const visualizationMode =
    data?.visualizationMode?.value[0] || locatorVisualizationModes.NO_MAP;

  const internalLocatorIcon =
    data?.internalLocatorContent?.content?.iconSvgIdinteralLocatorIcon?.value ||
    'locator-with-angel-and-stars';
  const internalLocatorIconThickness =
    data?.internalLocatorContent?.content?.iconSvgIdinteralLocatorIcon?.content
      ?.iconThicknessMap?.value[0];
  const descriptionText =
    data?.internalLocatorContent?.content.descriptionText?.value ||
    "Trova l'agenzia o la struttura convenzionata più vicina a te.";
  const descriptionTag =
    data?.internalLocatorContent?.content.descriptionText?.content
      ?.descriptionHtmlTag?.value;
  const locatorLink =
    data?.internalLocatorContent?.content.externalLocatorPage?.value || ''; // by default is the root of the site
  const locatorAnchor = slugify(
    data?.internalLocatorContent?.content.externalLocatorPage?.content
      ?.externalLocatorAnchor?.value || '',
    { lower: true, strict: true }
  ); // fallback when the anchor is not defined
  const inputPlaceholder =
    data?.internalLocatorContent?.content.inputPlaceholder?.value ||
    'Città, CAP';
  const aboveInputText =
    data?.internalLocatorContent?.content.aboveInputText?.value || 'Vicino a';
  const buttonSearchText =
    data?.internalLocatorContent?.content.buttonSearchText?.value || 'Cerca';

  return (
    <Wrapper
      data-module-name='agency-locator'
      data-module-code='C-24'
      className={classNames(`agency-locator__wrapper wrapper ${marginTop}`, {
        'agency-locator--colored-background': backgroundColor !== 'transparent',
        'wrapper--overflow': locatorVisualizationModes.MAP,
      })}
      tag={htmlTagWrapper}
      style={{ '--bg': `var(--${backgroundColor})` }}
    >
      <AnchorHooking id={anchorLabel} belowMenu={considerMenuSpace} />
      {(title || titleIconId) && (
        <Title
          tag={titleHeadingTag}
          titleIconId={titleIconId}
          titleIconThickness={titleIconThickness}
          titleIconColor='green'
          iconComponent={Icon}
        >
          {title}
        </Title>
      )}
      {visualizationMode === locatorVisualizationModes.MAP ? (
        <ExternalLocator
          src={externalAgencyLocatorUrl}
          iframeAttributes={iframeAttributes}
        />
      ) : (
        <InternalLocator
          descriptionTag={descriptionTag}
          aboveInputText={aboveInputText}
          buttonSearchText={buttonSearchText}
          backgroundColor={backgroundColor}
          htmlTagWrapper={htmlTagWrapper}
          inputPlaceholder={inputPlaceholder}
          internalLocatorIcon={internalLocatorIcon}
          internalLocatorIconThickness={internalLocatorIconThickness}
          locatorLink={locatorLink}
          locatorAnchor={locatorAnchor}
          text={descriptionText}
          marginTop={marginTop}
        />
      )}
    </Wrapper>
  );
};

export default withPreview(AgencyLocator);
